import { NavLink, useMatches, useOutletContext } from "@remix-run/react";
import * as React from "react";
import { type NavigationLink } from "../../types/graphql.js";
import MenuIcon from "./icons/menu.tsx";
import { Button } from "./ui/button.tsx";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog.tsx";

export default function Navigation() {
  let outletLinks = useOutletContext<{ links: NavigationLink[] }>();
  const {
    primary,
  } = // @ts-ignore
    useMatches()?.find((match) => match.id === "root")?.data.navigation;
  const [open, setOpen] = React.useState(false);
  const links = primary ?? outletLinks;

  return (
    <nav className="container mt-11 flex flex-row justify-between ">
      <div className="logo">
        <NavLink to="/">
          <img
            src="/assets/logo.svg"
            alt="Settoon Capital"
            className="w-[251px]"
            width="251"
            height="58"
          />
        </NavLink>
      </div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button className="pl-5 lg:hidden" aria-label="Menu">
            <MenuIcon className="h-8 w-8 fill-white" />
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] bg-white">
          <DialogHeader>
            <DialogTitle>
              <NavLink to="/" prefetch="intent" onClick={() => setOpen(false)}>
                <img
                  src="/assets/logo-green.svg"
                  alt="Settoon Capital"
                  className="w-[251px]"
                />
              </NavLink>
            </DialogTitle>
          </DialogHeader>
          <ul className="mt-12 flex flex-col space-y-4 text-lg uppercase tracking-wide text-gray-100">
            {links?.map((link: NavigationLink) => (
              <NavLink
                to={`/${link.nodeUri}`}
                key={link.id}
                prefetch="intent"
                onClick={() => setOpen(false)}
                className={({ isActive }) =>
                  `${isActive ? "text-copper-100" : ""} hover:text-copper-100`
                }
              >
                <li>{link.title}</li>
              </NavLink>
            ))}
          </ul>
        </DialogContent>
      </Dialog>
      <ul className="hidden text-base uppercase tracking-wider text-white lg:flex lg:flex-row lg:place-items-center lg:space-x-7 xl:space-x-14">
        {links?.map((link: NavigationLink) => (
          <NavLink
            to={`/${link.nodeUri}`}
            key={link.id}
            prefetch="intent"
            className={({ isActive }) =>
              `${isActive ? "text-copper-100" : ""} hover:text-copper-100`
            }
          >
            <li>{link.title}</li>
          </NavLink>
        ))}
      </ul>
    </nav>
  );
}
